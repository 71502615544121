<div class="banner-container" *ngIf="shipment.status !== 'assigned' && shipment.status !== 'confirmation pending'">
  <div class="banner" *ngIf="isDocumentNameACI || isDocumentNameACE">
    <mat-icon svgIcon="files"></mat-icon>
    <span>eManifest Available</span>
  </div>
</div>
<div fxLayout="column" ngClass="item-container" data-test="item-container">
  <div (click)="goToShipmentMyDetails()">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="shipment-id">{{ shipment.shipmentID }}</span>
      <span [ngClass]="[getStatusClass(), 'status-tag']" data-test="shipment-status">
        {{ shipment.status === 'out for delivery' ? 'In Transit' : (shipment.status | titlecase) }}
      </span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="margin-top-sm" *ngIf="coDriverName">
      <span class="co-driver">Co-Driver</span>
      <span class="co-driver-name">{{ coDriverName }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" class="margin-top-md">
      <div>
        <div fxLayout="column" fxFlex="4">
          <span class="dot origin"><mat-icon svgIcon="location"></mat-icon></span>
          <span class="vertical-line"></span>
          <span class="dot"><mat-icon svgIcon="location"></mat-icon></span>
        </div>
        <div
          (click)="openGoogleMapsDirections($event)"
          fxLayout="column"
          class="margin-left-md time-offer-row"
          fxLayoutAlign="space-between"
          fxFlex="100"
        >
          <div fxLayoutAlign="space-between">
            <a class="locationLabel" appBlurElement>{{ pickupAddress.updatedPickupAddress }}</a>
          </div>
          <a class="locationLabel margin-top-md" appBlurElement>{{ deliveryAddress?.updatedDeliveryAddress }}</a>
        </div>
      </div>
      <div class="offer-rate" *ngIf="shipment.rateVisibility !== false">
        $ {{ shipment.offerRate }}
        <span class="currency-txt" *ngIf="shipment.rateCurrency">{{ shipment.rateCurrency | uppercase }}</span>
      </div>
    </div>
    <div fxLayout="row" class="margin-top-md">
      <div fxLayout="column">
        <span class="margin-bottom-sm"
          >{{ pickupAddress.updatedPickupDate }}, {{ pickupAddress.updatedPickupTime }}</span
        >
        <span>{{ deliveryAddress.updatedDeliveryDate }}, {{ deliveryAddress.updatedDeliveryTime }}</span>
      </div>
    </div>
    <div class="margin-top-md sections">
      <div fxLayout="row">
        <mat-icon svgIcon="truck"></mat-icon>
        <div fxLayout="column">
          <span class="title">Unit</span>
          <span class="value">
            {{ shipment.unitNumber || '-' }}
          </span>
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon svgIcon="trailer"></mat-icon>
        <div fxLayout="column">
          <span class="title">Trailer</span>
          <span class="value">
            {{ shipment.trailerNumber || '-' }}
          </span>
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon svgIcon="trailer"></mat-icon>
        <div fxLayout="column">
          <span class="title">Load Type</span>
          <span class="value">
            {{ loadTypeMap[shipment.loadType] || '-' }}
          </span>
        </div>
      </div>
    </div>
    <div class="margin-top-md sections">
      <div fxLayout="row">
        <mat-icon svgIcon="truck-icon"></mat-icon>
        <div fxLayout="column">
          <span class="title">Load Size</span>
          <span class="value">
            {{ getLoad() }}
          </span>
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon svgIcon="trips-grey"></mat-icon>
        <div fxLayout="column">
          <span class="title">Miles</span>
          <span class="value">{{ shipment.distance ? shipment.distance + ' miles' : '-' }} </span>
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon svgIcon="sort"></mat-icon>
        <div fxLayout="column">
          <span class="title">DH-O/DH-D</span>
          <span class="value">
            {{ getDeadhead() }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="margin-top-md" *ngIf="showConfirm()">
    <button mat-button class="btn-accept" (click)="confirmShipment()" data-test="confirm-button">Confirm</button>
  </div>

  <div *ngIf="showChangeStatus()">
    <div class="margin-top-md">
      <div>
        <button
          *ngIf="
            !(
              (shipment.status === 'pickup in progress' ||
                shipment.status === 'out for delivery' ||
                shipment.status === 'unloading') &&
              shipment.pickDropType === 'sequence'
            )
          "
          mat-button
          class="btn-accept"
          (click)="onChangeShipmentStatus()"
          [disabled]="disableChangeStatus() || loading"
          data-test="change-status-button"
        >
          Change Status
        </button>
        <div *ngIf="displayStopsFeatureButton && !shipmentHistory">
          <button
            *ngIf="
              (shipment.status === 'pickup in progress' ||
                shipment.status === 'out for delivery' ||
                shipment.status === 'unloading') &&
              shipment.pickDropType === 'sequence'
            "
            mat-button
            class="btn-accept margin-top-sm"
            (click)="goToShipmentsStops()"
            [disabled]="disableChangeStatus() || loading"
            data-test="stops-view-button"
          >
            Stops ({{ this.statusComplete }}/{{ this.stops?.length || '0' }})
          </button>
        </div>
      </div>
      <button
        mat-button
        class="btn-accept btn-document "
        (click)="goToShipmentDocuments()"
        [disabled]="loading"
        data-test="document-button"
      >
        Documents
      </button>
    </div>
    <div class="margin-top-md contact-row">
      <div (click)="onAddShipmentComment()"><mat-icon svgIcon="comments" data-test="comments"></mat-icon></div>
      <div (click)="onAddShipmentPhoto()"><mat-icon svgIcon="camera" data-test="camera"></mat-icon></div>
      <div (click)="getContact()"><mat-icon svgIcon="phonecall" data-test="phonecall"></mat-icon></div>
      <div (click)="goToShipmentMyStatus()"><mat-icon svgIcon="history-line" data-test="status"></mat-icon></div>
    </div>
  </div>
</div>
