<div class="upload-doc-content" data-test="uploaded-form">
  <div *ngIf="!isUploading">
    <div class="added-file" *ngIf="uploadedFile">
      <div fxFlex="100" class="added-icon" (click)="viewDocument(uploadedFile)">
        <div class="added-display-icon" fxFlex="10">
          <mat-icon svgIcon="icon-file-blue" class="added-icon-color"></mat-icon>
        </div>
        <div fxFlex="90" class="file-name" data-test="uploaded-file-name">
          <p>{{ uploadedFile.fileName }}</p>
        </div>
      </div>

      <div class="delete-icon" fxFlex="20" (click)="deleteDocuments(uploadedFile)">
        <mat-icon svgIcon="delete-icon"></mat-icon>
      </div>
    </div>

    <!--- Toggle Buttons -->
    <div *ngIf="!hideTabs; else additonalDocumentUpload">
      <div *ngIf="!uploadedFile && !isRequested" class="tab-toggle">
        <mat-tab-group
          animationDuration="0ms"
          mat-align-tabs="center"
          mat-stretch-tabs="true"
          (selectedTabChange)="isRequestCollect($event)"
        >
          <mat-tab label="Add File">
            <div class="tab-content">
              <ng-template [ngTemplateOutlet]="additonalDocumentUpload"></ng-template>
            </div>
          </mat-tab>
          <mat-tab label="Request Carrier">
            <div class="tab-content" *ngIf="isCarrierRequested">
              <div class="add-file-request" (click)="onRequestCarrierUpload()">
                <mat-icon svgIcon="upload-file-tree"></mat-icon
                ><span class="padding-left-sm request-upload">Request Carrier Upload</span>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <!-- Document requested view -->
    <div *ngIf="!uploadedFile && isRequested">
      <div class="document-requested">
        <mat-icon svgIcon="upload-file-tree"></mat-icon
        ><span class="padding-left-sm request-upload">Document upload pending</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center" class="padding-top-lg">
        <span class="heading">Carrier upload requested</span>
        <span class="body padding-top-sm"
          >You will be able to view and download the document once the Carrier uploads.</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template class="view-date" #additonalDocumentUpload>
  <form [formGroup]="form">
    <div class="view-date" *ngIf="!isFastCard">
      <mat-form-field class="full-width">
        <input
          matInput
          [matDatepicker]="generationDate"
          placeholder="GENERATION DATE"
          formControlName="generationDate"
          required
          [max]="currentDate"
          [disabled]="uploadedFile"
          data-test="generation-date"
          readonly
          (click)="generationDate.open()"
          (dateInput)="setExpireMinDate($event)"
          (dateChange)="setExpireMinDate($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="generationDate" data-test="select-date"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #generationDate></mat-datepicker>
        <mat-error *ngIf="form.get('generationDate').invalid">
          {{ required }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="view-date" *ngIf="hideTabs && isIssued">
      <mat-form-field class="full-width">
        <input
          matInput
          [matDatepicker]="issueDate"
          placeholder="ISSUE DATE"
          formControlName="issueDate"
          required
          [max]="currentDate"
          [disabled]="uploadedFile"
          data-test="issue-date"
          readonly
          (click)="issueDate.open()"
          (dateInput)="setExpireMinDate($event)"
          (dateChange)="setExpireMinDate($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="issueDate" data-test="select-date"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #issueDate></mat-datepicker>
        <mat-error *ngIf="form.get('issueDate').invalid">
          {{ required }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="hideTabs && isExpiring" class="view-date">
      <mat-label>{{ generationDate?.value }}</mat-label>
      <mat-form-field class="full-width">
        <input
          matInput
          [matDatepicker]="validToDate"
          placeholder="EXPIRATION DATE"
          formControlName="validToDate"
          required
          [min]="expireMinDate"
          [disabled]="uploadedFile"
          data-test="valid-to-date"
          readonly
          (click)="validToDate.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="validToDate" data-test="select-date"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #validToDate></mat-datepicker>
        <mat-error *ngIf="form.get('validToDate').invalid">
          {{ required }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="view-date" *ngIf="hideTabs && isFastCard">
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="FAST Card Number"
          formControlName="fastCardNumber"
          required
          [disabled]="uploadedFile"
          data-test="fast-card-number"
        />
        <mat-error *ngIf="form.get('fastCardNumber').invalid">
          {{ required }}
        </mat-error>
      </mat-form-field>
    </div>
    <ng-template [ngTemplateOutlet]="additonalDocumentValidToDate"></ng-template>
    <div
      *ngIf="!uploadedFile"
      [ngClass]="
        (form.get('generationDate')?.value || form.get('issueDate')?.value) && !isUploading && !documentPicture ? 'add-file' : 'add-file-disabled'
      "
      (click)="
        (form.get('generationDate')?.value || form.get('issueDate')?.value) && !isUploading && !documentPicture
          ? openActionSheet(pictureSingle, uploadMultiple)
          : null
      "
    >
      <div class="add-file-content"><mat-icon svgIcon="icon-file-blue" class="icon-color"></mat-icon> + Add File</div>
    </div>
    <div class="inputs-container">
      <input
        #uploadMultiple
        type="file"
        [accept]="acceptTypesMultiple"
        (change)="onUploadFileChange($event)"
        [disabled]="!(form.get('generationDate')?.value || form.get('issueDate')?.value) && !isUploading"
        data-test="add-file"
      />
      <input
        #pictureSingle
        type="file"
        name="image"
        [accept]="acceptTypesPicture"
        capture="environment"
        (change)="onProcessPictureFile($event)"
        [disabled]="!(form.get('generationDate')?.value || form.get('issueDate')?.value) && !isUploading"
        data-test="add-file"
      />
    </div>
    <mat-error *ngIf="uploadError">
      {{ uploadError }}
    </mat-error>
  </form>

  <div class="content" *ngIf="showCrop && environment?.featureFlags?.enableDQFDocImageCropFeature">
    <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
      <image-cropper
        #previewImage
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [imageQuality]="100"
        [aspectRatio]="4 / 4"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        format="png"
        (imageCropped)="onImageCropped($event)"
        (imageLoaded)="onImageLoaded()"
        (cropperReady)="onCropperReady()"
        (loadImageFailed)="onLoadImageFailed()"
      >
      </image-cropper>
    </div>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
      <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
        <button
          class="margin-bottom secondary-button"
          mat-raised-button
          (click)="onCancelCrop()"
          data-test="finish-button"
        >
          Cancel
        </button>
        <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
          <mat-icon>rotate_left</mat-icon>
        </button>
        <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
          <mat-icon>rotate_right</mat-icon>
        </button>
        <button
          class="margin-bottom primary-button finish-crop-button"
          mat-raised-button
          (click)="onFinishCrop()"
          data-test="finish-button"
        >
          Finish
        </button>
      </div>
    </div>
  </div>
</ng-template>
