import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ShipmentsDataService } from '../../shared/shipments-data.service';
import { first, tap, catchError, finalize } from 'rxjs/operators';
import { DEFAULT_ERROR_MESSAGE, STATUS_ASSIGNED, STATUS_CONFIRMATION_PENDING, STOPS } from '../../shared/constants';
import { ShipmentsUtilsService } from '../../shared/shipments-utils.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_STATUS, SHIPMENTS_ROUTE_MY_SHIPMENTS } from '../../shared/routes';

const INSTRUCTION_KEYS = [
  'driverAssistedPickup',
  'forkLiftPickup',
  'liftgatePickup',
  'tarpPickup',
  'tieDownsPickup',
  'driverAssistedDelivery'
];
const INSTRUCTION_KEYS_MAP = {
  driverAssistedPickup: 'Driver Assisted Pickup',
  forkLiftPickup: 'Fork lift',
  liftgatePickup: 'Lift gate',
  tarpPickup: 'Tarp',
  tieDownsPickup: 'Tie-Downs',
  driverAssistedDelivery: 'Driver Assisted Delivery'
};
const SHIPMENT_TYPE_ONGOING = 'Ongoing';
const SHIPMENT_TYPE_UPCOMING = 'Upcoming';
@Component({
  selector: 'app-shipment-sub-detail',
  templateUrl: './shipment-sub-detail.component.html',
  styleUrls: ['./shipment-sub-detail.component.scss']
})
export class ShipmentSubDetailComponent implements OnInit {
  @Input() shipment: Shipment;
  pickupInstructionChips = [];
  deliveryInstructionChips = [];
  shipmentId: string;
  isContentReady: boolean = false;
  pickupFormattedDateTime: string | Date;
  deliveryFormattedDateAndTime: string | Date;
  shipmentType: string = '';
  loadTypeMap = {
    liveLoad: 'Live Load',
    hookAndDrop: 'Hook and Drop'
  };
  stops;
  pickupStop;
  deliveryAddress;
  pickupAddress;
  pageType: string = '';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly toastService: ToastService,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly shipmentsUtilsService: ShipmentsUtilsService,
    private readonly dateTimeService: DateTimeService,
    private readonly route: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.pageType = params['pageType'];
    });
    if (!this.shipmentId && this.pageType !== 'history') {
      this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
      this.getShipmentDetails();
    }
    const stateData = window.history.state;
    this.shipment = stateData?.shipment || null;
    if(this.shipment){
      this.isContentReady = true;
      this.getShipmentValues(this.shipment)
    }
  }

  navigateBack(): void {
    this.location.back();
  }

  navigateToDocuments(): void {
    let path = `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${this.shipment.id}/documents`;
    this.route.navigate([path]);
  }

  navigateToMyStatus(): void {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_STATUS}/${this.shipment.id}`;
    this.route.navigate([path]);
  }

  getShipmentStatusClass(): string {
    return this.shipmentsUtilsService.getShipmentStatusClass(this.shipment.status);
  }

  getShipmentType(): void {
    if (this.shipment.status !== STATUS_CONFIRMATION_PENDING && this.shipment.status !== STATUS_ASSIGNED) {
      this.shipmentType = SHIPMENT_TYPE_ONGOING;
    } else {
      this.shipmentType = SHIPMENT_TYPE_UPCOMING;
    }
  }

  getShipmentDetails(): void {
      this.isContentReady = false;
      this.shipmentsDataService
        .getShipmentDetails(this.shipmentId)
        .pipe(
          first(),
          tap((response: Shipment) => {
            if (response) {
              this.shipment = response;
              this.getShipmentValues(this.shipment);
            }
          }),
          catchError(error => {
            this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
            return error;
          }),
          finalize(() => {
            this.isContentReady = true;
          })
        )
        .subscribe();
  }

  getShipmentValues(shipment) {
    this.pickupInstructionChips = this.getChips(shipment?.pickup);
    this.deliveryInstructionChips = this.getChips(shipment?.delivery);
    this.pickupFormattedDateTime = shipment?.pickupFormattedDateTime;
    this.deliveryFormattedDateAndTime = shipment?.deliveryFormattedDateAndTime;
    this.getShipmentType();
    if (Array.isArray(shipment.delivery)) {
      this.stops = [...shipment.delivery];
      this.stops.sort((a, b) => a.order - b.order);
    } else {
      this.stops = [shipment.delivery];
    }
    if (shipment.pickup) {
      this.pickupStop = shipment.pickup;
      this.pickupStop.setOrder = 1;
      this.stops.unshift(this.pickupStop);
    }
    for (let i = 1; i < this.stops.length; i++) {
      this.stops[i].setOrder = i + 1;
    }
    const pickupStops = [STOPS.HOOK_TRAIKER, STOPS.PICK_LOAD];
    const pickups = this.stops.filter(el => pickupStops.includes(el.stopType));
    const dropStops = [STOPS.DELIVER_LOAD, STOPS.DROP_TRAILER];
    const dropOffs = this.stops.filter(el => dropStops.includes(el.stopType));
    this.pickupAddress = this.shipmentsUtilsService.getPickupAddressDateTime(pickups, shipment);
    this.deliveryAddress = this.shipmentsUtilsService.getDeliveryAddressDateTime(dropOffs, shipment);
  }
  getChips(object: any): any[] {
    const chipArray = [];
    Object.keys(object).forEach(key => {
      if (INSTRUCTION_KEYS.includes(key) && object[key] !== false) {
        chipArray.push(INSTRUCTION_KEYS_MAP[key]);
      }
    });

    return chipArray;
  }
}
