import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ContactDetails } from 'src/app/shared/models/contact-details';
import { CommonDataService } from 'src/app/shared/services/common/common-data.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import {
  DEFAULT_ERROR_MESSAGE,
  LOAD_SIZE_FTL,
  STATUS_ASSIGNED,
  STATUS_CONFIRMATION_PENDING,
  STATUS_UNLOADING,
  STOPS
} from '../shared/constants';
import { ShipmentsActionsService } from '../shared/shipments-actions.service';
import { ShipmentsUtilsService } from '../shared/shipments-utils.service';
import { Router } from '@angular/router';
import {
  SHIPMENTS_ROUTE,
  SHIPMENTS_ROUTE_MY_SHIPMENTS,
  SHIPMENTS_ROUTE_MY_STATUS,
  SHIPMENTS_ROUTE_DETAILS,
  SHIPMENTS_ROUTE_STOPS_DETAILS
} from '../shared/routes';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';

const DISPATCHERS = 'dispatchers';
const EVENT_PROOF_OF_DELIVERY = 'PoD uploaded';
const POD_LABEL_TYPE = 'ProofOfDelivery';
const TIME_FORMAT = 'hh:mm a z';
const QUOTE_OWNER_ACCEPTED = 'owner accepted';

@Component({
  selector: 'app-shipments-item',
  templateUrl: './shipments-item.component.html',
  styleUrls: ['./shipments-item.component.scss']
})
export class ShipmentsItemComponent implements OnInit {
  @Input() shipment: any;
  @Input() isUpcoming: boolean;
  @Input() isOngoingFTL: boolean;
  @Input() isOngoingCount: number;
  @Input() shipmentHistory: boolean;
  @Output() onReload: EventEmitter<void> = new EventEmitter();
  @Output() onChangeStatus: EventEmitter<any> = new EventEmitter();
  formattedPickupDateAndTime: string;
  formattedDeliveryDateAndTime: string;
  driverId: string;
  loading: boolean;
  coDriverName: string;
  displayStopsFeatureButton;
  loadTypeMap = {
    liveLoad: 'Live Load',
    hookAndDrop: 'Hook and Drop'
  };
  deliveryZipcode: string;
  pickupZipcode: string;
  stops;
  pickupStop;
  statusComplete;
  STATUS_COMPLETE = 'complete';
  delivery;
  shipmentDocuments;
  isDocumentNameACE = false;
  isDocumentNameACI = false;
  pickupAddress;
  deliveryAddress;
  enableDriverConfirmation: any;

  constructor(
    private readonly shipmentUtilsService: ShipmentsUtilsService,
    private readonly shipmentsActionService: ShipmentsActionsService,
    private readonly dateTimeService: DateTimeService,
    private readonly driverApiService: DriverApiService,
    private readonly commonDataService: CommonDataService,
    private readonly toastService: ToastService,
    private readonly route: Router,
    private readonly documentsDataService: DocumentsDataService,
    @Inject('environmentData') private environment: any
  ) {}

  ngOnInit(): void {
    this.driverId = this.driverApiService.driverID;
    this.deliveryZipcode = this.shipment?.delivery?.zipcode;
    this.pickupZipcode = this.shipment?.pickup?.zipcode;
    this.formattedPickupDateAndTime = this.shipment.pickupFormattedDateTime;
    this.formattedDeliveryDateAndTime = this.shipment.deliveryFormattedDateAndTime;
    if (this.driverId === this.shipment.assignedId) {
      this.coDriverName = this.shipment.assigned2Id ? this.shipment.assigned2Name : '';
    } else if (this.driverId === this.shipment.assigned2Id) {
      this.coDriverName = this.shipment.assignedId ? this.shipment.assignedName : '';
    }
    this.displayStopsFeatureButton = this.environment?.featureFlags?.displayStopsFeatureButton;
    this.getStopsCount();
    this.getShipmentDocuments();
    this.enableDriverConfirmation = this.environment?.featureFlags?.enableDriverConfirmation;
  }

  getStopsCount() {
    this.delivery = this.shipment.delivery;
    if (Array.isArray(this.shipment.delivery)) {
      this.stops = [...this.shipment.delivery];
      this.stops.sort((a, b) => a.order - b.order);
    } else {
      this.stops = [this.shipment.delivery];
    }
    if (this.shipment.pickup) {
      this.pickupStop = this.shipment.pickup;
      this.pickupStop.setOrder = 1;
      this.stops.unshift(this.pickupStop);
    }
    for (let i = 1; i < this.stops.length; i++) {
      this.stops[i].setOrder = i + 1;
    }
    const pickupStops = [STOPS.HOOK_TRAIKER, STOPS.PICK_LOAD];
    const pickups = this.stops.filter(el => pickupStops.includes(el.stopType));
    const dropStops = [STOPS.DELIVER_LOAD, STOPS.DROP_TRAILER];
    const dropOffs = this.stops.filter(el => dropStops.includes(el.stopType));
    this.pickupAddress = this.shipmentUtilsService.getPickupAddressDateTime(pickups, this.shipment);
    this.deliveryAddress = this.shipmentUtilsService.getDeliveryAddressDateTime(dropOffs, this.shipment);
    this.statusComplete = this.stops.filter(item => item.status === this.STATUS_COMPLETE)?.length || 0;
  }

  openGoogleMapsDirections(event: Event) {
    event.stopPropagation();
    const origin = `${this.pickupAddress.updatedPickupAddress}`;
    const destination = `${this.deliveryAddress.updatedDeliveryAddress}`;
    this.shipmentUtilsService.openGoogleMaps(origin, destination);
  }

  getStatusClass(): string {
    return this.shipmentUtilsService.getShipmentStatusClass(this.shipment.status);
  }

  showConfirm(): boolean {
    if (this.shipmentHistory) {
      return false;
    } else if (this.enableDriverConfirmation) {
      return (
        (!this.isUpcoming && this.shipment.quotes[0]?.status === QUOTE_OWNER_ACCEPTED) ||
        (this.isUpcoming && this.shipment.quotes[0]?.status === QUOTE_OWNER_ACCEPTED)
      );
    } else {
      return this.isUpcoming && this.shipment.status === STATUS_CONFIRMATION_PENDING;
    }
  }

  showChangeStatus(): boolean {
    if (this.shipmentHistory) {
      return false;
    } else if (this.enableDriverConfirmation) {
      return (
        (!this.isUpcoming && this.shipment.quotes[0]?.status === 'confirmed') ||
        (this.isUpcoming && this.shipment.quotes[0]?.status === 'confirmed')
      );
    } else {
      return !this.isUpcoming || (this.isUpcoming && this.shipment.status !== STATUS_CONFIRMATION_PENDING);
    }
  }

  getLoad(): string {
    return this.shipmentUtilsService.getLoad(this.shipment);
  }

  getDeadhead(): string {
    return this.shipmentUtilsService.getDeadhead(this.shipment);
  }

  confirmShipment(): void {
    this.shipmentsActionService.confirmShipment(this.shipment, this.driverId);
  }

  goToShipmentDetails(queryParam?: any): void {
    if (!this.loading) {
      this.shipmentsActionService.navigateToShipmentDetails(this.shipment.id, queryParam);
    }
  }

  goToShipmentMyStatus() {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_STATUS}/${this.shipment.id}`;
    this.route.navigate([path]);
  }
  goToShipmentDocuments() {
    let path = `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${this.shipment.id}/documents`;
    this.route.navigate([path]);
  }

  onChangeShipmentStatus(): void {
    if (this.loading) {
      return;
    }
    if (this.shipment.status === STATUS_UNLOADING) {
      this.loading = true;
      this.documentsDataService
        .getShipmentDocuments(this.shipment.id)
        .pipe(
          first(),
          tap((response: ShipmentDocument[]) => {
            const document = response.filter(document => document.type === POD_LABEL_TYPE);
            this.openBottomSheet(document.length ? true : false);
          }),
          catchError(error => {
            const isPodAvailable = this.shipment?.events.filter(event => event.name === EVENT_PROOF_OF_DELIVERY);
            this.openBottomSheet(isPodAvailable.length ? true : false);
            this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
            return error;
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe();
    } else {
      this.openBottomSheet();
    }
  }

  async getShipmentDocuments(): Promise<void> {
    if (this.shipmentHistory) {
      return;
    } else if (this.shipment.quotes[0]?.status === 'confirmed') {
      if (this.shipment.status !== STATUS_ASSIGNED && this.shipment.status !== STATUS_CONFIRMATION_PENDING) {
        const shipmentIds = Array.isArray(this.shipment.id) ? this.shipment.id : [this.shipment.id];
        const promises = shipmentIds.map(id => this.documentsDataService.getShipmentDocuments(id).toPromise());

        try {
          const responses = await Promise.all(promises);
          responses.forEach((response: ShipmentDocument[]) => {
            this.shipmentDocuments = response;
            this.isDocumentNameACE = false;
            this.isDocumentNameACI = false;
            this.shipmentDocuments.forEach(item => {
              if (item.type === 'ACEeManifest' && !this.isDocumentNameACE) {
                this.isDocumentNameACE = true;
              }
              if (item.type === 'ACIeManifest' && !this.isDocumentNameACI) {
                this.isDocumentNameACI = true;
              }
            });
          });
        } catch (error) {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
        }
      }
    }
  }

  goToShipmentsStops() {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_STOPS_DETAILS}/${this.shipment.id}`;
    this.route.navigate([path]);
  }

  openBottomSheet(isPodAvailable?: boolean) {
    const bottomSheet = this.shipmentsActionService.openChangeShipmentStatusBottomSheet(
      this.shipment,
      true,
      (newStatus: string) => this.onChangeStatus.emit({ shipment: this.shipment, newStatus }),
      isPodAvailable
    );
    bottomSheet.afterDismissed().subscribe(data => {
      if (data?.navigateToDocument) {
        let path = `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${this.shipment.id}/documents`;
        this.route.navigate([path]);
      }
    });
  }

  goToShipmentMyDetails() {
    if(this.shipmentHistory){
      let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_DETAILS}/${this.shipment.id}`;
      this.route.navigate([path], {
        queryParams: { pageType: 'history' },
        state: { shipment: this.shipment }
      });
    } else {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_DETAILS}/${this.shipment.id}`;
    this.route.navigate([path]);
    }
  }

  onAddShipmentComment(): void {
    if (this.loading) {
      return;
    }

    this.shipmentsActionService.openAddShipmentCommentBottomSheet(this.shipment, false, () => this.onReload.emit());
  }

  onAddShipmentPhoto(): void {
    if (this.loading) {
      return;
    }

    this.shipmentsActionService.openAddShipmentPhotoBottomSheet(this.shipment, () => this.onReload.emit());
  }

  getContact(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.commonDataService
      .getUserById(this.shipment.companyUserId, DISPATCHERS)
      .pipe(
        first(),
        map((response: ContactDetails) => {
          if (response) {
            window.open(`tel:${response.phoneNumber}`, '_self');
          }
        }),
        finalize(() => {
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
          return error;
        })
      )
      .subscribe();
  }

  disableChangeStatus(): boolean {
    let disableBtn =
      this.isUpcoming && (this.isOngoingFTL || (!this.isOngoingFTL && this.shipment.loadSize === LOAD_SIZE_FTL));
    return !this.isUpcoming || !this.isOngoingCount ? false : disableBtn;
  }
}
