<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <span class="title">{{ getHeaderTitle() }}</span>
  </div>

  <div *ngIf="isApiLoading" class="loading-container">
    <div class="loading-content">
      <img src="/assets/icons/fleetoperate-dots-loading.gif" class="loading-gif" />
      <span class="upload-message">Upload in progress... Please wait and don't refresh the screen.</span>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="component-container padding-top-x2 padding-sides-1x upload-container"
    *ngIf="!isApiLoading && finalImageFiles?.length != 2 && !showCrop"
  >
    <img
      [src]="!isFrontSave ? 'assets/images/dl-front.png' : 'assets/images/dl-back.png'"
      class="license-placeholder"
    />
    <button mat-mini-fab color="primary" class="upload-button margin-top-md" (click)="openGallery(fileInput)">
      <mat-icon>add</mat-icon>
    </button>
    <input #fileInput type="file" hidden (change)="onProcessPictureFile($event)" [accept]="acceptTypesPicture" />
    <span class="upload-text">
      {{ !isFrontSave ? 'Upload front side of license' : 'Upload back side of license' }}
    </span>
    <span class="center margin-top-sm margin-bottom-sm disclaimer-text"
    >JPEG, JPG and PNG are supported document types.</span
  >
  </div>

  <div *ngIf="!isApiLoading && showCrop" class="cropper-container">
    <image-cropper
      #previewImage
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      [imageQuality]="70"
      cropperMinWidth="420"
      cropperMinHeight="220"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [format]="imageFormat"
      [alignImage]="'center'"
      (imageCropped)="onImageCropped($event)"
      (imageLoaded)="onImageLoaded()"
      (cropperReady)="onCropperReady()"
      (loadImageFailed)="onLoadImageFailed()"
    >
    </image-cropper>

    <!-- Cropper Controls -->
    <div class="cropper-controls">
      <button mat-raised-button (click)="onCancelCrop()">
        Cancel
      </button>
      <button mat-raised-button class="rotate-button" (click)="rotateLeft()">
        <mat-icon>rotate_left</mat-icon>
      </button>
      <button mat-raised-button class="rotate-button" (click)="rotateRight()">
        <mat-icon>rotate_right</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="onFinishCrop()">
        Finish
      </button>
    </div>
  </div>

  <div
    *ngIf="!isApiLoading && finalImageFiles?.length === 2"
    class="preview-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <div class="preview-image-container margin-bottom-md">
      <h3>Front Image:</h3>
      <div class="image-wrapper">
        <div *ngIf="isPreviewLoading" class="preview-loading">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <img
          [src]="frontImageUrl"
          class="license-image"
          alt="Front license"
          [class.hide]="isPreviewLoading"
          (load)="onPreviewImageLoad()"
          (error)="onPreviewImageError()"
        />
      </div>
    </div>

    <div class="preview-image-container">
      <h3>Back Image:</h3>
      <div class="image-wrapper">
        <div *ngIf="isPreviewLoading" class="preview-loading">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <img
          [src]="backImageUrl"
          class="license-image"
          alt="Back license"
          [class.hide]="isPreviewLoading"
          (load)="onPreviewImageLoad()"
          (error)="onPreviewImageError()"
        />
      </div>
    </div>
  </div>

  <!-- Action Buttons for Preview State -->
  <div *ngIf="!isApiLoading && finalImageFiles?.length === 2" class="action-buttons">
    <button mat-raised-button (click)="resetLicense()">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="saveLicense()">
      Save
    </button>
  </div>
</div>
